import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Field } from 'redux-form';

import add from '../../assets/svgs/add.svg';
import deleteSmall from '../../assets/svgs/delete-small.svg';
import comments from '../../assets/svgs/comments.svg';
import historyLog from '../../assets/svgs/history-log.svg';
import mail from '../../assets/svgs/mail.svg';
import pdf from '../../assets/svgs/pdf.svg';
import pdfnoBrand from '../../assets/svgs/pdfnoBrand.svg';
import newbrochureIconBranded from '../../assets/svgs/newbrochureIconBranded.svg';
import newbrochureIconNonBranded from '../../assets/svgs/newbrochureIconNonBranded.svg';
import favorite from '../../assets/svgs/favorite.svg';
import favoriteEmpty from '../../assets/svgs/favorite-empty.svg';
import vatPaid from '../../assets/svgs/vat-paid.svg';
import vatUnpaid from '../../assets/svgs/vat-unpaid.svg';
import yachts from '../../assets/svgs/yachts_default.svg';
import editIcon from '../../assets/svgs/edit.svg';

import HistoryLog from './HistoryLog';
import CommentsModal from './CommentsModal';
import SendMailModal from './SendMailModal';
import StatusEdit from './YachtStatusEdit';

import { API_URL, vatStatus } from '../../helpers/variables';
import SimpleModal from '../../common/components/SimpleModal';
import ToggleCollapse from '../../common/components/ToggleCollapse';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderField from '../../common/components/RenderField';
import RenderToggle from '../../common/components/RenderToggle';
import moment from "moment";

import { functions } from '../../helpers/functions';
import YachtStatus from './YachtStatus';

class ResultView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            modalHeader: 'History Log',
            modalBody: <HistoryLog yachtId={props.yacht.id} />,
            modalDefault: 'logs',
            settings: {
                dots: true,
                autoplay: true,
                arrows: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
            },
            showExternalUrl: false
        };

        this.modalConfiguration = {
            logs: {
                title: 'History Log',
                body: ({ yacht }) => <HistoryLog yachtId={yacht.id} />
            },
            mail: {
                title: 'Settings',
                body: ({ yacht, viewType }) => (
                    <SendMailModal yachtId={yacht.id} viewType={viewType} />
                )
            },
            status: {
                title: 'Edit Status',
                body: ({ yacht, onYachtStatusChange, viewType }) => (
                    <StatusEdit
                        yacht={yacht}
                        viewType={viewType}
                        onStatusChange={(status) => {
                            onYachtStatusChange(yacht, status);
                            this.toggleModal(null, 'status');
                        }}
                        closeModal={this.closeModal}
                    />
                )
            },
            comments: {
                title: 'Comments',
                body: ({ yacht }) => (
                    <CommentsModal
                        newComments={yacht.new_comments}
                        yachtId={yacht.id}
                    />
                )
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    check(e, yacht) {
        this.props.onCheckboxChange(e, yacht);
    }

    getModalConfiguration(type) {
        return (
            this.modalConfiguration[type] || this.modalConfiguration.comments
        );
    }

    showYachtPhoto() {
        const { viewType, yacht } = this.props;

        if (
            (viewType === 'sales' && yacht.photo) ||
            (viewType === 'charter' && !yacht.charter_photo && yacht.photo)
        ) {
            return { backgroundImage: `url(${yacht.photo})` };
        }

        if (
            (viewType === 'sales' && !yacht.photo && yacht.charter_photo) ||
            (viewType === 'charter' && yacht.charter_photo)
        ) {
            return { backgroundImage: `url(${yacht.charter_photo})` };
        }

        return {
            backgroundImage: `url(${yachts})`,
            backgroundSize: '80px 70px'
        };
    }

    toggleModal(e, type) {
        this.setState({
            modalHeader: this.getModalConfiguration(type).title,
            modalBody: this.getModalConfiguration(type).body(this.props),
            modalDefault: type,
            modalOpen: !this.state.modalOpen
        });
    }

    closeModal() {
        this.setState({
            modalOpen: false
        });
    }

    getCurationName(curation_level) {
        switch (curation_level) {
            case 1:
                return 'Curation level 1';
            case 2:
                return 'Curation level 2';
            case 3:
                return 'Curation level 3';
            case -1:
                return 'Curation level 0';
            default:
                return null;
        }
    }

    activeToggle() {
        this.setState({ showExternalUrl: !this.state.showExternalUrl });
    }

    render() {
        const {
            yacht,
            allCheckbox,
            selected,
            categories,
            viewType
        } = this.props;
        let selectedPool =
            viewType === 'sales'
                ? yacht.selected_on_sales
                    ? true
                    : false
                : yacht.selected_on_charter
                ? true
                : false;

        if (this.props.shouldUseSelected) {
            selectedPool = yacht.selected ? true : false;
        }

        return (
            <div className="result-yacht-wrapper">
                {this.props.view === true &&
                    (this.props.userRole === 'broker' ||
                        this.props.userRole === 'broker_sales' ||
                        this.props.userRole === 'broker_charter') &&
                    this.props.hideActions === undefined && (
                        <div className="d-flex">
                            {yacht.sales === 1 && (
                                <button
                                    type="button"
                                    className={
                                        'ml-auto mb-1 btn btn-blue btn-smaller ' +
                                        (yacht.selected_on_sales
                                            ? 'btn-red'
                                            : 'btn-search')
                                    }
                                    onClick={(e) =>
                                        this.props.selectYacht(
                                            e,
                                            yacht,
                                            'sales'
                                        )
                                    }
                                >
                                    <ReactSVG
                                        path={selected ? deleteSmall : add}
                                        style={{ height: selected ? 16 : 10 }}
                                    />
                                    {yacht.selected_on_sales === 1
                                        ? 'Remove from sales selection'
                                        : 'Add to sales selection'}
                                </button>
                            )}

                            {yacht.chartering === 1 && (
                                <button
                                    type="button"
                                    className={
                                        'ml-1 mb-1 btn btn-blue btn-smaller ' +
                                        (yacht.selected_on_charter
                                            ? 'btn-red'
                                            : 'btn-search')
                                    }
                                    onClick={(e) =>
                                        this.props.selectYacht(
                                            e,
                                            yacht,
                                            'charter'
                                        )
                                    }
                                >
                                    <ReactSVG
                                        path={selected ? deleteSmall : add}
                                        style={{ height: selected ? 16 : 10 }}
                                    />
                                    {yacht.selected_on_charter === 1
                                        ? 'Remove from charter selection'
                                        : 'Add to charter selection'}
                                </button>
                            )}
                        </div>
                    )}

                <div className="yacht-header-wrapper">
                    <div className="row align-items-center">
                        {this.props.belongsToCategory && (
                            <div className="col-3 yacht-heading  d-flex align-items-start">
                                {this.props.belongsToCategory}
                            </div>
                        )}
                        <div
                            className={`col-3 yacht-heading  d-flex align-items-start  ${
                                this.props.showCheckbox === undefined ||
                                this.props.showCheckbox
                                    ? 'flex-row justify-content-start'
                                    : 'flex-column justify-content-between'
                            }`}
                        >
                            {(this.props.showCheckbox === undefined ||
                                this.props.showCheckbox) &&
                                !this.props.readOnly && (
                                    <RenderCheckbox
                                        disabled={
                                            allCheckbox !== undefined
                                                ? !allCheckbox
                                                : false
                                        }
                                        input={{
                                            value: selectedPool,
                                            name: `exclude_${yacht.id}`,
                                            onChange: (e) =>
                                                this.check(e, yacht)
                                        }}
                                    />
                                )}
                            <div>
                                <div className="yacht-name">
                                    <NavLink
                                        target="_blank"
                                        to={`/yachts/view/${viewType}/${yacht.id}`}
                                    >
                                        {yacht.name}
                                    </NavLink>
                                </div>

                                {yacht.previous_names && (
                                    <div className="previous-name-wrapper">
                                        Pr. Name:
                                        <div className="previous-name d-inline-block">
                                            {yacht.previous_names}
                                        </div>
                                    </div>
                                )}
                                {yacht.is_archived ? (
                                    <div className="yacht-status-container yacht-heading">
                                        <div className="yacht-is_archived">
                                            Archived {yacht.delete_on ? `(Marked For Deletion on ${moment(yacht.delete_on).format('YYYY-MM-DD')})` : ''}
                                        </div>
                                    </div>
                                ) : null}

                                <div className="yacht-heading yacht-name">
                                    {this.getCurationName(yacht.curation_level)}
                                </div>
                            </div>
                        </div>

                        <div className="col-3 yacht-heading">
                            {viewType === 'sales' &&
                            yacht.asking_price_displayed ? (
                                <div className="heading-label small-head">
                                    Asking price
                                </div>
                            ) : null}

                            {viewType === 'charter' &&
                            yacht.future_rates &&
                            yacht.future_rates.length > 0 ? (
                                <div className="heading-label small-head">
                                    Rates start from
                                </div>
                            ) : null}

                            {viewType === 'sales' &&
                            yacht.asking_price_displayed ? (
                                <div className="heading-body">
                                    {functions.currency(
                                        yacht.asking_price_displayed,
                                        yacht?.asking_price_currency,
                                        true
                                    )}
                                </div>
                            ) : null}

                            {viewType === 'charter' &&
                                yacht.future_rates &&
                                yacht.future_rates.length > 0 && (
                                    <div className="heading-body">
                                        {functions.currency(
                                            yacht.future_rates[0].rate,
                                            yacht?.future_rates[0].currency,
                                            true
                                        )}
                                        + expenses
                                    </div>
                                )}
                        </div>
                        <div className="col-3 yacht-heading">
                            {viewType === 'sales' && (
                                <div className="field-outer-wrapper">
                                    <YachtStatus yacht={yacht} />
                                </div>
                            )}
                        </div>
                        <div className="col-3 yacht-heading yacht-actions">
                            <ul className="list-unstyled table-td-actions">
                                {!functions.isBroker(this.props.userRole) &&
                                    !this.props.hideActions && (
                                        <li>
                                            <button
                                                type="button"
                                                className="btn btn-td-action"
                                                onClick={(e) =>
                                                    this.toggleModal(
                                                        e,
                                                        'status'
                                                    )
                                                }
                                            >
                                                <ReactSVG
                                                    path={editIcon}
                                                    style={{ height: 20 }}
                                                />
                                            </button>
                                        </li>
                                    )}
                                {!functions.isBroker(this.props.userRole) &&
                                    !this.props.hideActions && (
                                        <li>
                                            <button
                                                type="button"
                                                className="btn btn-td-action"
                                                onClick={(e) =>
                                                    this.toggleModal(e, 'logs')
                                                }
                                            >
                                                <ReactSVG
                                                    path={historyLog}
                                                    style={{ height: 20 }}
                                                />
                                            </button>
                                        </li>
                                    )}

                                {!this.props.hideActions && (
                                    <li>
                                        <button
                                            type="button"
                                            className="btn btn-td-action"
                                            onClick={(e) =>
                                                this.toggleModal(e, 'mail')
                                            }
                                        >
                                            <ReactSVG
                                                path={mail}
                                                style={{ height: 20 }}
                                            />
                                        </button>
                                    </li>
                                )}

                                {!this.props.hideActions && (
                                    <li>
                                        <button
                                            type="button"
                                            className={
                                                'btn btn-td-action comments ' +
                                                (yacht.new_comments
                                                    ? 'unread-comments'
                                                    : '')
                                            }
                                            onClick={(e) =>
                                                this.toggleModal(e, 'comments')
                                            }
                                        >
                                            <ReactSVG
                                                path={comments}
                                                style={{ height: 20 }}
                                            />
                                        </button>
                                    </li>
                                )}

                                {!this.props.hideActions && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href={
                                                API_URL +
                                                '/specsPdf/' +
                                                yacht.id +
                                                '?type=' +
                                                viewType
                                            }
                                            rel="noreferrer"
                                        >
                                            <ReactSVG
                                                path={pdf}
                                                style={{ height: 20 }}
                                            />
                                        </a>
                                    </li>
                                )}

                                {!this.props.hideActions && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href={
                                                API_URL +
                                                '/specsPdfNoBrand/' +
                                                yacht.id +
                                                '?type=' +
                                                viewType
                                            }
                                            rel="noreferrer"
                                        >
                                            <ReactSVG
                                                path={pdfnoBrand}
                                                style={{
                                                    height: 20
                                                }}
                                            />
                                        </a>
                                    </li>
                                )}

                                {!this.props.hideActions && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href={
                                                API_URL +
                                                '/brochure/' +
                                                yacht.id +
                                                '?type=' +
                                                viewType
                                            }
                                            rel="noreferrer"
                                        >
                                            <ReactSVG
                                                path={newbrochureIconBranded}
                                                style={{
                                                    height: 20
                                                }}
                                            />
                                        </a>
                                    </li>
                                )}

                                {!this.props.hideActions && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href={
                                                API_URL +
                                                '/brochureNoBrand/' +
                                                yacht.id +
                                                '?type=' +
                                                viewType
                                            }
                                            rel="noreferrer"
                                        >
                                            <ReactSVG
                                                path={newbrochureIconNonBranded}
                                                style={{
                                                    height: 20
                                                }}
                                            />
                                        </a>
                                    </li>
                                )}
                            </ul>
                            {categories ? (
                                <div className="yacht-newsletter-category">
                                    <Field
                                        name={`category-template-${yacht.id}`}
                                        input={{
                                            value: yacht.category
                                                ? yacht.category
                                                : '',
                                            onChange: this.props.changeYachtCategory.bind(
                                                this
                                            )
                                        }}
                                        placeholder="YACHT CATEGORY"
                                        readOnly={this.props.readOnly}
                                        disabled={this.props.readOnly}
                                        component={RenderReactSelect}
                                        className="yacht-newsletter-category-field"
                                        options={categories}
                                    />
                                </div>
                            ) : null}

                            {viewType === 'charter' &&
                                this.props.showExternalUrl &&
                                this.props.selected && (
                                    <div className="text-right">
                                        <RenderToggle
                                            name={'active' + yacht.id}
                                            label="Enable external url"
                                            input={{
                                                value:
                                                    yacht?._pivot_external_url &&
                                                    yacht?._pivot_external_url !==
                                                        null &&
                                                    yacht?._pivot_external_url !==
                                                        ''
                                                        ? true
                                                        : this.state
                                                              .showExternalUrl,
                                                onChange: (event, v) =>
                                                    this.activeToggle(event)
                                            }}
                                        />
                                        {(this.state.showExternalUrl ||
                                            (yacht?._pivot_external_url &&
                                                yacht?._pivot_external_url !==
                                                    null &&
                                                yacht?._pivot_external_url !==
                                                    '')) && (
                                            <Field
                                                name={'external_url' + yacht.id}
                                                type="text"
                                                className="mb-1"
                                                placeholder="EXTERNAL URL"
                                                readOnly={this.props.readOnly}
                                                component={RenderField}
                                                onBlur={(event) =>
                                                    !this.props.readOnly
                                                        ? this.props.onChangeExternalUrl(
                                                              yacht.id,
                                                              event
                                                          )
                                                        : null
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

                <div className="yacht-body-wrapper">
                    <div className="row">
                        <div className="col-lg-3 col-6 order-1 mb-md-2">
                            <NavLink
                                target="_blank"
                                to={`/yachts/view/${viewType}/${yacht.id}`}
                            >
                                <div
                                    className={`yacht-image mb-1 ${
                                        (!yacht.photo ||
                                            !yacht.charter_photo) &&
                                        'background-yacht-filling'
                                    }`}
                                    style={this.showYachtPhoto()}
                                >
                                    {this.props.view === undefined &&
                                    yacht.private_deed &&
                                    yacht.private_deed === 1 ? (
                                        <div className="yacht-status-label green right">
                                            PRIVATE DEAL
                                        </div>
                                    ) : null}
                                </div>
                            </NavLink>

                            <div className="row mb-1 align-items-center">
                                <div className="col-12 text-left">
                                    {this.props.favouriteYacht !== null ? (
                                        <button
                                            type="button"
                                            className="btn btn-empty btn-favorite"
                                            onClick={(e) =>
                                                this.props.favouriteYacht(yacht)
                                            }
                                        >
                                            <ReactSVG
                                                path={
                                                    yacht.favourite === 1
                                                        ? favorite
                                                        : favoriteEmpty
                                                }
                                                style={{ height: 16 }}
                                            />
                                        </button>
                                    ) : (
                                        <ReactSVG
                                            path={
                                                yacht.favourite === 1
                                                    ? favorite
                                                    : favoriteEmpty
                                            }
                                            style={{ height: 16 }}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    {yacht.sales === 1 ? (
                                        <div className="yacht-category yacht-sales">
                                            SALES
                                        </div>
                                    ) : null}

                                    {yacht.chartering === 1 ? (
                                        <div className="yacht-category yacht-chartering">
                                            CHARTER
                                        </div>
                                    ) : null}

                                    {yacht.external_providers
                                        ? yacht.external_providers.map(
                                              (externalProvider) => {
                                                  return (
                                                      <div
                                                          key={
                                                              externalProvider.name
                                                          }
                                                          className="yacht-category yacht-yatco text-uppercase"
                                                      >
                                                          {
                                                              externalProvider.name
                                                          }
                                                      </div>
                                                  );
                                              }
                                          )
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6 order-3 order-lg-2">
                            {yacht.vat_status &&
                            yacht.vat_status !== null &&
                            yacht.vat_status !== '' ? (
                                <div className="mb-2">
                                    <div className="heading-label small-head">
                                        Vat status
                                    </div>
                                    <div className="heading-body">
                                        {yacht.vat_status === 1 ||
                                        yacht.vat_status === 0 ? (
                                            <ReactSVG
                                                path={
                                                    yacht.vat_status === 1
                                                        ? vatPaid
                                                        : vatUnpaid
                                                }
                                                style={{ height: 16 }}
                                            />
                                        ) : vatStatus.find(
                                              (x) => x.id === yacht.vat_status
                                          ) ? (
                                            vatStatus.find(
                                                (x) => x.id === yacht.vat_status
                                            ).name
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>
                            ) : null}

                            {yacht.external_providers &&
                            functions.isAdmin(this.props.userRole)
                                ? yacht.external_providers.map(
                                      (externalProvider) => {
                                          return (
                                              <div
                                                  key={externalProvider.name}
                                                  className="mb-1"
                                              >
                                                  <div className="heading-label small-head">
                                                      {externalProvider.name +
                                                          ' '}
                                                      Vessel Id
                                                  </div>
                                                  <div className="heading-body">
                                                      {
                                                          externalProvider._pivot_external_yacht_id
                                                      }
                                                  </div>
                                              </div>
                                          );
                                      }
                                  )
                                : null}

                            {yacht.types && yacht.types !== null ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Type
                                    </div>
                                    <div className="heading-body">
                                        {yacht.types
                                            ? yacht.types
                                                  .map((t) => t.text)
                                                  .join(', ')
                                            : '-'}
                                    </div>
                                </div>
                            ) : null}

                            {yacht.loa &&
                            yacht.loa !== null &&
                            yacht.loa !== '' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        LOA
                                    </div>
                                    <div className="heading-body">
                                        {functions.unit(
                                            yacht.loa,
                                            yacht.loa_unit,
                                            true
                                        )}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' &&
                            yacht.beam &&
                            yacht.beam !== null &&
                            yacht.beam !== '' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Beam
                                    </div>
                                    <div className="heading-body">
                                        {functions.unit(
                                            yacht.beam,
                                            yacht.beam_unit,
                                            true
                                        )}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' &&
                            ((yacht.draft_min &&
                                yacht.draft_min !== null &&
                                yacht.draft_min !== '') ||
                                (yacht.draft_max &&
                                    yacht.draft_max !== null &&
                                    yacht.draft_max !== '')) ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Draft min - max
                                    </div>
                                    <div className="heading-body">
                                        {yacht.draft_min
                                            ? '(MIN) ' +
                                              functions.unit(
                                                  yacht.draft_min,
                                                  yacht.draft_min_unit,
                                                  true
                                              )
                                            : ''}
                                        {yacht.draft_max && yacht.draft_min
                                            ? ' - '
                                            : ''}
                                        {yacht.draft_max
                                            ? '(MAX) ' +
                                              functions.unit(
                                                  yacht.draft_max,
                                                  yacht.draft_max_unit,
                                                  true
                                              )
                                            : ''}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' &&
                            ((yacht.cabins &&
                                yacht.cabins !== null &&
                                yacht.cabins !== '') ||
                                (yacht.guests &&
                                    yacht.guests !== null &&
                                    yacht.guests !== '')) ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Cabins / Guests
                                    </div>
                                    <div className="heading-body">
                                        {!yacht.cabins ? '-' : yacht.cabins} /{' '}
                                        {!yacht.guests ? '-' : yacht.guests}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'charter' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Cabins / Guests For Charter
                                    </div>
                                    <div className="heading-body">
                                        {yacht.cabins_charter
                                            ? yacht.cabins_charter
                                            : '-'}
                                        {yacht.guests_sleeping
                                            ? ' / ' + yacht.guests_sleeping
                                            : ' / -'}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' &&
                            yacht.crew &&
                            yacht.crew !== null &&
                            yacht.crew !== '' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Crew
                                    </div>
                                    <div className="heading-body">
                                        {yacht.crew}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'charter' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Crew
                                    </div>
                                    <div className="heading-body">
                                        {yacht.crew_charter
                                            ? yacht.crew_charter
                                            : '-'}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'charter' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Speed Cruising / Max Speed
                                    </div>
                                    <div className="heading-body">
                                        {yacht.charter_speed_cruising
                                            ? yacht.charter_speed_cruising
                                            : '-'}
                                        {yacht.speed_max
                                            ? ' / ' + yacht.speed_max
                                            : ' / -'}
                                    </div>
                                </div>
                            ) : null}

                            {yacht.tonnage &&
                            yacht.tonnage !== null &&
                            yacht.tonnage !== '' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Gross tonnage
                                    </div>
                                    <div className="heading-body">
                                        {yacht.tonnage ? yacht.tonnage : '-'}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="col-lg-3 col-6 order-4 order-lg-3">
                            {yacht.previous_names &&
                            yacht.previous_names !== null &&
                            yacht.previous_names !== '' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Previous names
                                    </div>
                                    <div className="heading-body">
                                        {yacht.previous_names}
                                    </div>
                                </div>
                            ) : null}

                            {yacht.builder &&
                            yacht.builder !== null &&
                            yacht.builder !== '' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Builder
                                    </div>
                                    <div className="heading-body">
                                        {yacht.builder}
                                    </div>
                                </div>
                            ) : null}

                            <div className="mb-1">
                                <div className="heading-label small-head">
                                    Year built / Year refit
                                </div>
                                <div className="heading-body">
                                    {yacht.year_built ? yacht.year_built : '-'}{' '}
                                    /{' '}
                                    {yacht.year_refit ? yacht.year_refit : '-'}
                                </div>
                            </div>

                            {viewType === 'sales' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Hull / Superstructure material
                                    </div>
                                    <div className="heading-body">
                                        {yacht.hull_material
                                            ? yacht.hull_material
                                            : '-'}{' '}
                                        /{' '}
                                        {yacht.superstructure_material
                                            ? yacht.superstructure_material
                                            : '-'}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Engine
                                    </div>
                                    <div className="heading-body">
                                        {yacht.engine ? yacht.engine : '-'}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        Country
                                    </div>
                                    <div className="heading-body">
                                        {yacht.country ? yacht.country : '-'}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' &&
                            yacht.country === 'United States' &&
                            yacht.state != null ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        State
                                    </div>
                                    <div className="heading-body">
                                        {yacht.state}
                                    </div>
                                </div>
                            ) : null}

                            {viewType === 'sales' ? (
                                <div className="mb-1">
                                    <div className="heading-label small-head">
                                        City
                                    </div>
                                    <div className="heading-body">
                                        {yacht.city}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="col-lg-3 col-6 order-2 order-lg-4">
                            {viewType === 'sales' &&
                                yacht.sales_brokers &&
                                yacht.sales_brokers.length > 0 && (
                                    <div className="brokers-carousel">
                                        <Slider {...this.state.settings}>
                                            {yacht.sales_brokers.map(
                                                (broker, i) => {
                                                    return (
                                                        <div
                                                            className="slide-wrap"
                                                            key={i}
                                                        >
                                                            {broker.photo &&
                                                                broker.photo !==
                                                                    '' && (
                                                                    <div
                                                                        className="image-wrap"
                                                                        style={{
                                                                            backgroundImage: `url(${broker.photo})`
                                                                        }}
                                                                    />
                                                                )}
                                                            <div className="mt-1 mb-1">
                                                                <div className="heading-label small-head">
                                                                    Broker
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.name
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mb-1">
                                                                <div className="heading-label small-head">
                                                                    Contact
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.contact
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mb-1">
                                                                <div className="heading-label small-head">
                                                                    Mobile
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.mobile
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="heading-label small-head">
                                                                    Main
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.main
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Slider>
                                    </div>
                                )}

                            {viewType === 'charter' &&
                                yacht.chartering_brokers &&
                                yacht.chartering_brokers.length > 0 && (
                                    <div className="brokers-carousel">
                                        <Slider {...this.state.settings}>
                                            {yacht.chartering_brokers.map(
                                                (broker, i) => {
                                                    return (
                                                        <div
                                                            className="slide-wrap"
                                                            key={i}
                                                        >
                                                            {broker.photo &&
                                                                broker.photo !==
                                                                    '' && (
                                                                    <div
                                                                        className="image-wrap"
                                                                        style={{
                                                                            backgroundImage: `url(${broker.photo})`
                                                                        }}
                                                                    />
                                                                )}
                                                            <div className="mt-1 mb-1">
                                                                <div className="heading-label small-head">
                                                                    Charter
                                                                    Manager
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.name
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mb-1">
                                                                <div className="heading-label small-head">
                                                                    Contact
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.contact
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mb-1">
                                                                <div className="heading-label small-head">
                                                                    Mobile
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.mobile
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="heading-label small-head">
                                                                    Main
                                                                </div>
                                                                <div className="heading-body">
                                                                    {
                                                                        broker.main
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Slider>
                                    </div>
                                )}
                        </div>

                        {yacht.notes && (
                            <div className="col-12 mt-2 yacht-notes order-5">
                                <ToggleCollapse
                                    html={true}
                                    body={yacht.notes}
                                    header="NOTES"
                                    wrapperClassName="unify-text"
                                    collapseClassName="mt-1"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.modalBody}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal}
                    modalClassName={
                        this.state.modalDefault === 'logs' ? '' : 'lg'
                    }
                    className={
                        this.state.modalDefault === 'logs' ? '' : 'modal-lg'
                    }
                />
            </div>
        );
    }
}

export default ResultView;
