import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm, formValueSelector } from 'redux-form';
import Notifications from 'react-notification-system-redux';
import { Button } from 'reactstrap';
import $ from 'jquery';
import alerts from 'locales/alerts';
import { warning } from 'react-notification-system-redux';
import { NavLink } from 'react-router-dom';

import RenderRedactor from 'common/components/RenderRedactor';
import RenderField from 'common/components/RenderField';
import RenderReactSelect from 'common/components/RenderReactSelect';
import RenderCheckbox from 'common/components/RenderCheckbox';
import RenderFavorite from 'common/components/RenderFavorite';
import { required, normalizePrice } from 'helpers/validate';
import { vatStatus, dropdowns } from 'helpers/variables';
import { functions } from 'helpers/functions';

import UploadField from 'common/components/UploadField';

import Brokers from 'brokers/multiple/Brokers';
import builders from 'infrastructure/data/builders';
import {isOwner, isSuperAdmin} from 'infrastructure/helpers/roles';
import { parseNumberInput } from 'common/utils';
import ReactSVG from 'react-svg';
import deleteSVG from 'assets/svgs/delete-small.svg';
import DeleteConfirmation from 'common/components/DeleteConfirmation';
import SimpleModal from 'common/components/SimpleModal';

import {
    fetchManageYachts,
    fetchYacht,
    checkYachtNameExists,
    getYachtMissingFields,
    getYachtArchiveValidation,
    fetchDeleteYacht
} from '../_actions';
import { getRealRates } from 'helpers/rates/_actions';
import MissingFieldsWarningModal from './MissingFieldsWarningModal';
import moment from "moment";

const form = reduxForm({
    form: 'manage_yacht',
    enableReinitialize: true
});

let def = 'en';

const selector = formValueSelector('manage_yacht');

const currencyFields = ['asking_price', 'estimated_price'];
const unitFields = ['loa', 'beam', 'draft_min', 'draft_max'];
const squaredUnitFields = ['master_cabin', 'sundeck'];
const charterFields = [
    'charter_photo',
    'subtitle',
    'charter_speed_cruising',
    'charter_fuel_consumption',
    'cabins_charter',
    'guests_charter',
    'cabin_layout',
    'crew_charter',
    'charter_info',
    'guests_static',
    'guests_private',
    'security_deposit',
    'contract_type',
    'guests_sleeping',
    'guests_sleeping_beds',
    'crew_passengers_ratio',
    'crew_passengers_ratio_private',
    'vat_applicable',
    'checked',
    'registry_port',
    'tax_status',
    'special_conditions',
    'location_details',
    'rate_details',
    'accommodations_text',
    'beds_configuration',
    'contract_notes',
    'apa'
];

class FormBasics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: false,
            loaded: false,
            nameWarning: false,
            charteringMatches: [],
            salesMatches: [],
            showModal: false,
            messageSalesSpecs: [],
            messageCharterSpecs: [],
            messageSalesWebsite: [],
            messageCharterWebsite: [],
            messageGeneral: [],
            messageRates: [],
            canBeArchived: true,
            modalHeader: 'Delete yacht',
            modalOpen: false
        };
    }

    componentDidMount() {
        if (
            this.props.initialValues &&
            this.props.initialValues.chartering === true
        ) {
            this.setState({ chartering: true });
        } else {
            this.setState({ chartering: false });
        }

        if (
            this.props.initialValues &&
            this.props.initialValues.sales === true
        ) {
            this.setState({ sales: true });
        } else {
            this.setState({ sales: false });
        }

        if (
            this.props.initialValues &&
            this.props.initialValues.is_archived === true
        ) {
            this.setState({ is_archived: true });
        } else {
            this.setState({ is_archived: false });
        }
        if (this.props.initialValues.id) {
            getYachtArchiveValidation(this.props.initialValues.id).then(
                (res) => {
                    if (res.data === false) {
                        this.setState({
                            canBeArchived: false
                        });
                    }
                }
            );
        }
    }

    handleFormSubmit(formProps) {
        let params = Object.assign({}, formProps);
        delete params.selected; //remove unwanted field
        let valid = true;
        params.favourite = params.favourite === true ? 1 : 0;

        if (
            $('#yacht_notes').length > 0 &&
            params['yacht_notes'] !== undefined
        ) {
            params.notes = document.getElementById('yacht_notes').value;
        }

        if (params.updated_at) delete params.updated_at;
        if (params.created_at) delete params.created_at;

        params.delete_on = params.delete_on === 'Invalid date' ? null : (params.delete_on ? moment().add(30, 'days').format('YYYY-MM-DD') : null);

        params.sales = params.sales === true ? 1 : 0;
        params.chartering = params.chartering === true ? 1 : 0;

        params.checked = params.checked === true ? 1 : 0;

        if (
            params.sales === 1 &&
            params.chartering !== 1 &&
            (!params.photo || params.photo === '')
        ) {
            valid = false;
            this.props.notification(
                'Error',
                'Please upload a sales yacht photo'
            );
        }

        if (params.sales !== 1 && params.chartering !== 1) {
            valid = false;
            this.props.notification(
                'Error',
                'At least one option (sales/charter) needs to be selected.'
            );
        }

        if (params.is_archived && !this.state.canBeArchived) {
            valid = false;
            this.props.notification(
                'Error',
                'Yachts that are public on the website may not be archived. Make sure you uncheck the “Is public” option in English and Russian for both charter and sales, if the yacht identifies as such before archiving.'
            );
        }

        const hasRequiredInfoSales =
            !params.status ||
            (params.status !== 'pending' && params.status !== 'for_sale') ||
            params.guests < 2 ||
            params.loa <= 0 ||
            params.year_built <= 0;

        const hasRequiredInfoCharter =
            params.guests_sleeping < 2 ||
            params.loa <= 0 ||
            params.year_built <= 0;

        if (
            !this.state.canBeArchived &&
            params.sales === 1 &&
            this.props.viewType === 'sales'
        ) {
            if (hasRequiredInfoSales) {
                valid = false;
                this.props.notification(
                    'Warning',
                    <>
                        <p>
                            This yacht is missing some basic information before
                            it can be shown on the website. Please make sure to
                            correctly fill in:
                        </p>
                        <li>Guests (at least 2)</li>
                        <li>LOA (greater than 0)</li>
                        <li>Year built (greater than 0)</li>
                        <li>Sales status (For Sale/Pending)</li>
                    </>
                );
            }
        }

        if (
            !this.state.canBeArchived &&
            params.chartering === 1 &&
            this.props.viewType === 'charter'
        ) {
            if (hasRequiredInfoCharter) {
                valid = false;
                this.props.notification(
                    'Warning',
                    <>
                        <p>
                            This yacht is missing some basic information before
                            it can be shown on the website. Please make sure to
                            correctly fill in:
                        </p>
                        <li>Guests sleeping charter (at least 2)</li>
                        <li>LOA (greater than 0)</li>
                        <li>Year built (greater than 0)</li>
                        <li>Rates & Locations (for the present and future)</li>
                    </>
                );
            }
        }

        if (
            params.chartering === 1 &&
            params.sales !== 1 &&
            (!params.charter_photo || params.charter_photo === '')
        ) {
            valid = false;
            this.props.notification(
                'Error',
                'Please upload a charter yacht photo'
            );
        }

        if (
            params.sales === 1 &&
            params.chartering === 1 &&
            (!params.photo || params.photo === '') &&
            (!params.charter_photo || params.charter_photo === '')
        ) {
            valid = false;
            this.props.notification(
                'Error',
                'Please upload either sales or charter yacht photo'
            );
        }

        if (params.country !== 'United States') {
            params.state = '';
        }

        params.sales_brokers = params.sales_brokers
            ? params.sales_brokers
                  .map(function (br) {
                      if (br?.id) {
                          return br.id;
                      }
                      return null;
                  })
                  .filter((brokerIds) => brokerIds)
            : [];

        params.chartering_brokers = params.chartering_brokers
            ? params.chartering_brokers
                  .map(function (br) {
                      if (br?.id) {
                          return br.id;
                      }
                      return null;
                  })
                  .filter((brokerIds) => brokerIds)
            : [];

        currencyFields.forEach((f) => {
            if (params[f + '_displayed']) {
                params[f] = params[f + '_displayed']
                    .toString()
                    .replace(/,/g, '');
                params[f + '_displayed'] = params[f + '_displayed']
                    .toString()
                    .replace(/,/g, '');
            } else {
                params[f + '_displayed'] = null;
            }
        });

        unitFields.forEach((f) => {
            if (params[f]) {
                params[f] = functions.toMeters(params[f], params[f + '_unit']);
            } else {
                params[f] = null;
            }
        });

        squaredUnitFields.forEach((f) => {
            if (params[f]) {
                params[f] = functions.toSquareMeters(
                    params[f],
                    params[f + '_unit']
                );
            } else {
                params[f] = null;
            }
        });

        params.charter = {};
        charterFields.forEach((f) => {
            params.charter[f] = params[f];
            delete params[f];
        });

        delete params.amenities;

        if (this.props.initYacht.id) {
            params.id = this.props.initYacht.id;
        }

        if (params.last_for_sale !== undefined) {
            delete params.last_for_sale;
        }

        if (params.yacht_id !== undefined) {
            delete params.yacht_id;
        }

        if (params.future_rates !== undefined) {
            delete params.future_rates;
        }

        if (params.locations !== undefined) {
            delete params.locations;
        }

        // if the form has changes then we need to notify the backend so they can handle curation level updates etc
        // if the form has curation level 1 and other changes have been made except curation level the backend automatically changes curation level to 2
        // if the curation_level has changed by the user no automatic changes in curation level are dealt with in the backend
        if (
            params.id &&
            this.props.initialValues.curation_level !== params.curation_level
        ) {
            params.hasChanges = false;
        } else if (params.id) {
            params.hasChanges = !!this.props.dirty;
        }

        if (valid === true) {
            return this.props.fetchManageYachts(params).then((data) => {
                if (data?.hubspot_response?.error?.message) {
                    setTimeout(() => {
                        this.props.showWarning({
                            title: alerts.error.hubspotWarning[def].title,
                            message: [
                                data.hubspot_response.error.message + ' ',
                                data.hubspot_response.error.properties.map(
                                    function (prop) {
                                        return prop + ', ';
                                    }
                                )
                            ],
                            autoDismiss: 5
                        });
                    }, 1200);
                }
                if (params.id) {
                    getYachtMissingFields(params).then((res) => {
                        if (Object.keys(res.data).length !== 0) {
                            this.setState({ showModal: true });
                            if (res.data.sales?.specs) {
                                this.setState({
                                    messageSalesSpecs: res.data.sales.specs
                                });
                            }
                            if (res.data.charter?.specs) {
                                this.setState({
                                    messageCharterSpecs: res.data.charter.specs
                                });
                            }
                            if (res.data.charter?.rates) {
                                this.setState({
                                    messageRates: res.data.charter.rates
                                });
                            } else {
                                this.setState({ messageRates: null });
                            }
                            if (res.data.sales?.website) {
                                this.setState({
                                    messageSalesWebsite: res.data.sales.website
                                });
                            }
                            if (res.data.charter?.website) {
                                this.setState({
                                    messageCharterWebsite:
                                        res.data.charter.website
                                });
                            }
                            if (res.data.general) {
                                this.setState({
                                    messageGeneral: res.data.general
                                });
                            }
                        }
                    });
                }

                if (data) {
                    this.props.history.push(
                        `/yachts/edit/${this.props.viewType}/${data.id}`
                    );
                }
            });
        }
    }
    //consol
    editCustomerForm() {
        this.setState({ readOnly: false });
    }

    toggleModal = () => {
        this.setState({ showModal: false });
    };

    onNamecheck(event) {
        const yachtName = event.target.value;
        const path = window.location.pathname.split('/');

        if (yachtName) {
            checkYachtNameExists(yachtName).then((response) => {
                if (
                    response.data?.chartering.length > 0 ||
                    response.data?.sales.length > 0
                ) {
                    this.setState({
                        nameWarning: true,
                        salesMatches: response.data.sales.filter(
                            (yacht) => yacht.id !== parseInt(path[4])
                        ),
                        charteringMatches: response.data.chartering.filter(
                            (yacht) => yacht.id !== parseInt(path[4])
                        )
                    });
                }
                if (
                    (response.data?.chartering.length === 0 &&
                        response.data?.sales.length === 0) ||
                    (this.state.salesMatches.length === 0 &&
                        this.state.charteringMatches.length === 0)
                ) {
                    this.setState({ nameWarning: false });
                }
            });
        } else this.setState({ nameWarning: false });
    }

    handleCheckbox(event) {
        this.setState({
            [event.target.name]: Boolean(event.target.value !== 'true')
        });
    }

    toggleDeleteModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    deleteModal() {
        this.setState({
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleDeleteModal.bind(this)}
                    action={this.handleDeleteYacht.bind(this)}
                    body={
                        "You are about to delete yacht '" +
                        this.props.initYacht.name +
                        "'"
                    }
                />
            )
        });
        this.toggleDeleteModal();
    }

    handleDeleteYacht() {
        this.props
            .fetchDeleteYacht({ id: this.props.initYacht.id })
            .then((response) => {
                if (response) {
                    this.props.history.push(
                        `/yachts/view/${this.props.viewType}`
                    );
                }
            });
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        const { formValues } = this.props;

        return (
            <>
                <form
                    noValidate
                    className="list-form"
                    onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                >
                    <div className="row no-gutters">
                        <div className="col-8 pr-lg-1">
                            <div className="row">
                                <div className="col-11">
                                    <div className="name-wrapper">
                                        <Field
                                            name="name"
                                            type="text"
                                            placeholder="Boat name"
                                            validate={[required]}
                                            component={RenderField}
                                            onBlur={(e) => this.onNamecheck(e)}
                                        />

                                        <div className="extra-options-wrap d-flex align-items-center justify-content-end">
                                            <RenderFavorite
                                                favourite={this.props.favourite}
                                            />
                                        </div>
                                    </div>
                                    {this.state.nameWarning && (
                                        <div className="yacht-name-exists-warning box-container">
                                            <div className="yacht-name-exists-warning warning">
                                                Possible name matches found!
                                                Please check the following
                                                yachts to avoid creating
                                                duplicate entries!
                                            </div>
                                            <div>
                                                <p className="yacht-name-exists-warning-content label">
                                                    Charter Yachts:
                                                </p>
                                                {this.state.charteringMatches.map(
                                                    (chartering, key) => {
                                                        return (
                                                            <p
                                                                className="yacht-name-exists-warning-content link"
                                                                key={key}
                                                            >
                                                                <NavLink
                                                                    target="_blank"
                                                                    to={`/yachts/view/charter/${chartering.id}`}
                                                                >
                                                                    {`${chartering.name} (id: ${chartering.id}, Loa: ${chartering.loa}/${chartering.loa_unit}, Builder: ${chartering.builder}, Year Built: ${chartering.year_built})`}
                                                                </NavLink>
                                                            </p>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div>
                                                <p className="yacht-name-exists-warning-content label">
                                                    Sales Yachts:
                                                </p>
                                                {this.state.salesMatches.map(
                                                    (sales, key) => {
                                                        return (
                                                            <p
                                                                className="yacht-name-exists-warning-content link"
                                                                key={key}
                                                            >
                                                                <NavLink
                                                                    target="_blank"
                                                                    to={`/yachts/view/sales/${sales.id}`}
                                                                >
                                                                    {`${sales.name} (id: ${sales.id}, Loa: ${sales.loa}/${sales.loa_unit}, Builder: ${sales.builder}, Year Built: ${sales.year_built})`}
                                                                </NavLink>
                                                            </p>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="col-11 mb-1">
                                    <div className="row">
                                        <div className="col-xl-2 col-4">
                                            <Field
                                                name="chartering"
                                                label="CHARTER"
                                                group="chartering"
                                                type="checkbox"
                                                value="chartering"
                                                component={RenderCheckbox}
                                                onChange={(e) =>
                                                    this.handleCheckbox(e)
                                                }
                                            />
                                        </div>

                                        <div className="col-2">
                                            <Field
                                                name="sales"
                                                label="SALES"
                                                group="sales"
                                                type="checkbox"
                                                value="sales"
                                                component={RenderCheckbox}
                                                onChange={(e) =>
                                                    this.handleCheckbox(e)
                                                }
                                            />
                                        </div>

                                        {isSuperAdmin(this.props.user_role) && (
                                            <div className="col-2">
                                                <Field
                                                    name="is_archived"
                                                    label="ARCHIVED"
                                                    type="checkbox"
                                                    value="is_archived"
                                                    component={RenderCheckbox}
                                                    onChange={(function (e) {
                                                        const el = document.querySelector('#on-delete input');
                                                        
                                                        if (e.target.value === 'true' && (el.value !== 'false' && el.value)) {
                                                            el.click()
                                                        }
                                                            this.handleCheckbox(e)
                                                        }).bind(this)   
                                                    }
                                                />
                                            </div>
                                        )}


                                        {isSuperAdmin(this.props.user_role) && (
                                            <div id="on-delete" className="col-4">
                                                <Field
                                                    name="delete_on"
                                                    label="MARKED FOR DELETION"
                                                    type="checkbox"
                                                    value="delete_on"
                                                    component={RenderCheckbox}
                                                    disabled={!formValues.is_archived}
                                                    onChange={(e) =>
                                                        this.handleCheckbox(e)
                                                    }
                                                />
                                            </div>
                                        )}

                                        <div className="col-2 yacht-curation-level">
                                            <Field
                                                name="curation_level"
                                                placeholder="CURATION LEVEL"
                                                component={RenderReactSelect}
                                                clearable={false}
                                                options={
                                                    dropdowns['curation_level']
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-5">
                                    {this.state.sales && (
                                        <div className="row">
                                            <div className="col-8">
                                                <Field
                                                    name="asking_price_displayed"
                                                    type="text"
                                                    placeholder="ASKING PRICE"
                                                    normalize={normalizePrice}
                                                    component={RenderField}
                                                />
                                            </div>
                                            <div className="col-4 pl-0 pr-md-0">
                                                <Field
                                                    name="asking_price_currency"
                                                    placeholder="CURRENCY"
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    clearable={false}
                                                    validate={[required]}
                                                    options={[
                                                        {
                                                            value: 'usd',
                                                            label: 'USD'
                                                        },
                                                        {
                                                            value: 'eur',
                                                            label: 'EURO'
                                                        },
                                                        {
                                                            value: 'gbp',
                                                            label: 'GBP'
                                                        },
                                                        {
                                                            value: 'aud',
                                                            label: 'AUD'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <Field
                                        name="vessel_id"
                                        type="text"
                                        placeholder="VESSEL ID"
                                        component={RenderField}
                                        parse={(value) =>
                                            value
                                                ? Number(value)
                                                    ? Number(value)
                                                    : ''
                                                : null
                                        }
                                    />

                                    <Field
                                        name="types"
                                        placeholder="TYPE"
                                        component={RenderReactSelect}
                                        options={this.props.types}
                                        multi={true}
                                    />

                                    <div className="row">
                                        <div className="col-8">
                                            <Field
                                                name="loa"
                                                type="text"
                                                className="mt-0"
                                                placeholder="LOA"
                                                component={RenderField}
                                                parse={parseNumberInput}
                                                format={parseNumberInput}
                                            />
                                        </div>
                                        <div className="col-4 pl-0 pr-lg-1 pr-md-0">
                                            <Field
                                                name="loa_unit"
                                                placeholder="UNIT"
                                                className="mt-0"
                                                component={RenderReactSelect}
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'meters',
                                                        label: 'Meter'
                                                    },
                                                    {
                                                        value: 'feet',
                                                        label: 'Feet'
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <Field
                                                name="beam"
                                                type="number"
                                                placeholder="BEAM"
                                                className="mt-0"
                                                component={RenderField}
                                                parse={parseNumberInput}
                                                format={parseNumberInput}
                                            />
                                        </div>
                                        <div className="col-4 pl-0 pr-lg-1 pr-md-0">
                                            <Field
                                                name="beam_unit"
                                                placeholder="UNIT"
                                                component={RenderReactSelect}
                                                className="mt-0"
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'meters',
                                                        label: 'Meter'
                                                    },
                                                    {
                                                        value: 'feet',
                                                        label: 'Feet'
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <Field
                                                name="draft_min"
                                                type="number"
                                                className="mt-0"
                                                placeholder="DRAFT Min"
                                                component={RenderField}
                                                parse={parseNumberInput}
                                                format={parseNumberInput}
                                            />
                                        </div>
                                        <div className="col-4 pl-0 pr-lg-1 pr-md-0">
                                            <Field
                                                name="draft_min_unit"
                                                placeholder="UNIT"
                                                className="mt-0"
                                                component={RenderReactSelect}
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'meters',
                                                        label: 'Meter'
                                                    },
                                                    {
                                                        value: 'feet',
                                                        label: 'Feet'
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <Field
                                                name="draft_max"
                                                type="number"
                                                placeholder="DRAFT Max"
                                                className="mt-0"
                                                component={RenderField}
                                                parse={parseNumberInput}
                                                format={parseNumberInput}
                                            />
                                        </div>
                                        <div className="col-4 pl-0 pr-lg-1 pr-md-0">
                                            <Field
                                                name="draft_max_unit"
                                                placeholder="UNIT"
                                                className="mt-0"
                                                component={RenderReactSelect}
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'meters',
                                                        label: 'Meter'
                                                    },
                                                    {
                                                        value: 'feet',
                                                        label: 'Feet'
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <Field
                                                name="master_cabin"
                                                type="number"
                                                placeholder="MASTER CABIN"
                                                className="mt-0"
                                                component={RenderField}
                                                parse={parseNumberInput}
                                                format={parseNumberInput}
                                            />
                                        </div>
                                        <div className="col-4 pl-0 pr-lg-1 pr-md-0">
                                            <Field
                                                name="master_cabin_unit"
                                                placeholder="UNIT"
                                                className="mt-0"
                                                component={RenderReactSelect}
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'square_meters',
                                                        label: 'm²'
                                                    },
                                                    {
                                                        value: 'square_feet',
                                                        label: 'ft²'
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <Field
                                                name="sundeck"
                                                type="number"
                                                placeholder="SUNDECK"
                                                className="mt-0"
                                                component={RenderField}
                                                parse={parseNumberInput}
                                                format={parseNumberInput}
                                            />
                                        </div>
                                        <div className="col-4 pl-0 pr-lg-1 pr-md-0">
                                            <Field
                                                name="sundeck_unit"
                                                placeholder="UNIT"
                                                className="mt-0"
                                                component={RenderReactSelect}
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'square_meters',
                                                        label: 'm²'
                                                    },
                                                    {
                                                        value: 'square_feet',
                                                        label: 'ft²'
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <Field
                                        name="speed_max"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="MAXIMUM SPEED"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="speed_cruising"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="CRUISING SPEED"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="cabins"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="CABINS"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="guests"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="GUESTS"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="crew"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="CREW"
                                        className="mb-0"
                                        component={RenderField}
                                    />
                                </div>

                                <div className="col-5 offset-1">
                                    {this.state.sales && (
                                        <div className="row">
                                            <div className="col-8">
                                                <Field
                                                    name="estimated_price_displayed"
                                                    type="text"
                                                    placeholder="CLOSING ESTIMATE"
                                                    normalize={normalizePrice}
                                                    component={RenderField}
                                                />
                                            </div>
                                            <div className="col-4 pl-0 pr-lg-1 pr-md-0">
                                                <Field
                                                    name="estimated_price_currency"
                                                    placeholder="CURRENCY"
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    clearable={false}
                                                    validate={[required]}
                                                    options={[
                                                        {
                                                            value: 'usd',
                                                            label: 'USD'
                                                        },
                                                        {
                                                            value: 'eur',
                                                            label: 'EURO'
                                                        },
                                                        {
                                                            value: 'gbp',
                                                            label: 'GBP'
                                                        },
                                                        {
                                                            value: 'aud',
                                                            label: 'AUD'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <Field
                                        name="yachtfolio_id"
                                        placeholder="YACHTFOLIO ID"
                                        component={RenderField}
                                        parse={(value) =>
                                            value && isNaN(Number(value))
                                                ? ''
                                                : Number(value) || ''
                                        }
                                    />

                                    <Field
                                        name="vat_status"
                                        placeholder="VAT STATUS"
                                        component={RenderReactSelect}
                                        options={vatStatus}
                                    />

                                    <Field
                                        name="previous_names"
                                        type="text"
                                        placeholder="PREVIOUS NAME"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="builder"
                                        placeholder="BUILDER"
                                        className=" mt-0 mb-2"
                                        options={builders.map((builder) => ({
                                            value: builder,
                                            label: builder
                                        }))}
                                        multi={false}
                                        component={RenderReactSelect}
                                    />

                                    <Field
                                        name="year_built"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="YEAR BUILT"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="year_refit"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="YEAR REFIT"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="tonnage"
                                        parse={(value) =>
                                            value ? Number(value) : null
                                        }
                                        type="number"
                                        placeholder="TONNAGE GT"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="hull_type"
                                        type="text"
                                        placeholder="HULL TYPE"
                                        component={RenderReactSelect}
                                        options={dropdowns['hull_type']}
                                    />

                                    <Field
                                        name="hull_material"
                                        type="text"
                                        placeholder="HULL MATERIAL"
                                        component={RenderReactSelect}
                                        options={dropdowns['hull_material']}
                                    />

                                    <Field
                                        name="engine"
                                        type="text"
                                        placeholder="ENGINE"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="country"
                                        placeholder="COUNTRY"
                                        component={RenderReactSelect}
                                        options={this.props.countries}
                                    />

                                    <Field
                                        name="state"
                                        placeholder="STATE"
                                        component={RenderReactSelect}
                                        className={
                                            this.props.country !==
                                            'United States'
                                                ? 'disabled'
                                                : ''
                                        }
                                        options={this.props.states}
                                    />

                                    <Field
                                        name="city"
                                        type="text"
                                        placeholder="CITY"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="main_deck_cabin"
                                        type="checkbox"
                                        className="mb-0"
                                        label="MAIN DECK CABIN"
                                        component={RenderCheckbox}
                                    />
                                </div>

                                <div className="col-11 mt-3">
                                    <RenderRedactor
                                        label="YACHT DESCRIPTION"
                                        name="notes"
                                        onChange={(text) => {
                                            this.props.changeFieldValue(
                                                'notes',
                                                text
                                            );
                                        }}
                                        id="yacht_notes"
                                    />
                                </div>

                                {this.state.chartering && (
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 mt-4">
                                                <h3>Charter Specific Inputs</h3>
                                            </div>
                                            <div className="col-5">
                                                <Field
                                                    name="subtitle"
                                                    type="text"
                                                    placeholder="SUBTITLE"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="charter_speed_cruising"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="CHARTER CRUISING SPEED"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="charter_fuel_consumption"
                                                    type="text"
                                                    placeholder="CHARTER FUEL CONSUMPTION"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="cabins_charter"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="CABINS"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="cabin_layout"
                                                    type="text"
                                                    placeholder="CABIN LAYOUT"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="contract_type"
                                                    placeholder="CONTRACT TYPE"
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    options={[
                                                        {
                                                            label: 'MYBA',
                                                            value: 'MYBA'
                                                        },
                                                        {
                                                            label: 'AYCA',
                                                            value: 'AYCA'
                                                        },
                                                        {
                                                            label: 'Transport',
                                                            value: 'Transport'
                                                        }
                                                    ]}
                                                />

                                                <Field
                                                    name="apa"
                                                    type="number"
                                                    placeholder="APA"
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    options={dropdowns['apa']}
                                                />

                                                <Field
                                                    name="security_deposit"
                                                    label="SECURITY DEPOSIT REQUIRED"
                                                    type="checkbox"
                                                    value="security_deposit"
                                                    component={RenderCheckbox}
                                                />

                                                <Field
                                                    name="vat_applicable"
                                                    type="checkbox"
                                                    label="VAT APPLICABLE"
                                                    component={RenderCheckbox}
                                                />

                                                <Field
                                                    name="checked"
                                                    type="checkbox"
                                                    label="CHECKED AFTER SCAPING"
                                                    component={RenderCheckbox}
                                                />
                                            </div>

                                            <div className="col-5 offset-1">
                                                <Field
                                                    name="guests_charter"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="MAX GUESTS FOR CHARTER"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="guests_static"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="MAX GUESTS AT ANCHOR"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="guests_private"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="MAX GUESTS FOR PRIVATE USE"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="guests_sleeping"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="GUESTS SLEEPING"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="guests_sleeping_beds"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="GUESTS SLEEPING BEDS"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="crew_charter"
                                                    parse={(value) =>
                                                        value
                                                            ? Number(value)
                                                            : null
                                                    }
                                                    type="number"
                                                    placeholder="CREW"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="crew_passengers_ratio"
                                                    type="text"
                                                    placeholder="CREW PASSENGERS RATIO"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="crew_passengers_ratio_private"
                                                    type="text"
                                                    placeholder="CREW PASSENGERS RATIO (PRIVATE)"
                                                    component={RenderField}
                                                />

                                                <Field
                                                    name="registry_port"
                                                    type="text"
                                                    placeholder="REGISTRY PORT"
                                                    component={RenderField}
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="YACHT CHARTER INFO"
                                                    name="charter_info"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'charter_info',
                                                            text
                                                        );
                                                    }}
                                                    id="charter_info"
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="TAX STATUS"
                                                    name="tax_status"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'tax_status',
                                                            text
                                                        );
                                                    }}
                                                    id="tax_status"
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="SPECIAL CONDITIONS"
                                                    name="special_conditions"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'special_conditions',
                                                            text
                                                        );
                                                    }}
                                                    id="special_conditions"
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="LOCATIONS DETAILS"
                                                    name="location_details"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'location_details',
                                                            text
                                                        );
                                                    }}
                                                    id="location_details"
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="RATE DETAILS"
                                                    name="rate_details"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'rate_details',
                                                            text
                                                        );
                                                    }}
                                                    id="rate_details"
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="ACCOMMODATIONS"
                                                    name="accommodations_text"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'accommodations_text',
                                                            text
                                                        );
                                                    }}
                                                    id="accommodations_text"
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="BEDS CONFIGURATION"
                                                    name="beds_configuration"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'beds_configuration',
                                                            text
                                                        );
                                                    }}
                                                    id="beds_configuration"
                                                />
                                            </div>

                                            <div className="col-11 mt-3">
                                                <RenderRedactor
                                                    label="CONTRACT NOTES"
                                                    name="contract_notes"
                                                    onChange={(text) => {
                                                        this.props.changeFieldValue(
                                                            'contract_notes',
                                                            text
                                                        );
                                                    }}
                                                    id="contract_notes"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-4 d-flex flex-column pl-lg-1 pr-lg-2">
                            {isOwner(this.props.user_role) &&
                                this.props.initYacht.id && (
                                    <div className="mb-2">
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => this.deleteModal()}
                                        >
                                            <ReactSVG
                                                path={deleteSVG}
                                                style={{
                                                    height: 24
                                                }}
                                            />
                                            Delete Yacht
                                        </button>
                                    </div>
                                )}

                            {this.state.sales && (
                                <UploadField
                                    name="photo"
                                    box={true}
                                    desc="Dimension of photo should be 1920x1080"
                                    fileLabelName="UPLOAD MAIN SALES PHOTO"
                                    className="yacht-feature-photo"
                                    type="single"
                                    formName="manage_yacht"
                                    initPhoto={this.props.initYacht.photo}
                                />
                            )}

                            {this.state.chartering && (
                                <UploadField
                                    name="charter_photo"
                                    box={true}
                                    desc="Dimension of photo should be 1920x1080"
                                    fileLabelName="UPLOAD MAIN CHARTER PHOTO"
                                    className="yacht-feature-photo"
                                    type="single"
                                    formName="manage_yacht"
                                    initPhoto={
                                        this.props.initYacht.charter_photo
                                    }
                                />
                            )}

                            {this.state.sales && (
                                <div
                                    className="brokers-box"
                                    style={{ marginBottom: '40px' }}
                                >
                                    <h2
                                        style={{
                                            fontSize: '1.6rem',
                                            color: '#41506e',
                                            textAlign: 'center',
                                            textWeight: 400
                                        }}
                                    >
                                        Sales Brokers
                                    </h2>
                                    <Brokers
                                        form="manage_yacht"
                                        type="sales"
                                        prefix="sales_"
                                    />
                                </div>
                            )}

                            {this.state.chartering && (
                                <div className="brokers-box">
                                    <h2
                                        style={{
                                            fontSize: '1.6rem',
                                            color: '#41506e',
                                            textAlign: 'center',
                                            textWeight: 400
                                        }}
                                    >
                                        Charter Managers
                                    </h2>
                                    <Brokers
                                        form="manage_yacht"
                                        type="charter"
                                        prefix="chartering_"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8">
                            <div className="row">
                                <div className="col-11 mt-2 text-right">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={submitting}
                                    >
                                        Save
                                    </Button>{' '}
                                    <Button
                                        type="button"
                                        color="secondary"
                                        disabled={submitting}
                                        onClick={() => this.props.cancelForm()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.modalDelete}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleDeleteModal.bind(this)}
                />

                <MissingFieldsWarningModal
                    isOpen={this.state.showModal}
                    toggle={this.toggleModal}
                    messageSalesSpecs={this.state.messageSalesSpecs}
                    messageCharterSpecs={this.state.messageCharterSpecs}
                    messageSalesWebsite={this.state.messageSalesWebsite}
                    messageCharterWebsite={this.state.messageCharterWebsite}
                    messageGeneral={this.state.messageGeneral}
                    messageRates={this.state.messageRates}
                />
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('manage_yacht', field, value)),
        fetchManageYachts: (e) => dispatch(fetchManageYachts(e)),
        getRealRates: (e) => dispatch(getRealRates(e)),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        },
        showWarning: (options) => dispatch(warning(options)),
        fetchDeleteYacht: (e) => dispatch(fetchDeleteYacht(e))
    };
}

function mapStateToProps(state, ownProps) {
    let initialValues = {};

    if (ownProps.initYacht && ownProps.initYacht.id) {
        let data = Object.assign({}, state.yachts.yacht.initYacht);

        Object.keys(data).forEach((key) => {
            if (key === 'scraped') {
                initialValues.scraped = true;
            }
            if (key === 'sales') data.sales = Boolean(data?.sales === 1);
            else if (key === 'chartering')
                data.chartering = Boolean(data?.chartering === 1);
            else if (key === 'is_archived')
                data.is_archived = Boolean(data?.is_archived === 1);
            else if (key === 'delete_on')
                data.delete_on = data.delete_on ? moment(data.delete_on).format('YYYY-MM-DD') : null;
            else if (key === 'favourite')
                data.favourite = Boolean(data?.favourite === 1);
            else if (key === 'vat_status')
                data.vat_status =
                    data.vat_status || data.vat_status === 0
                        ? data.vat_status.toString()
                        : '';
            else if (key === 'brokers') {
                let brokers = [];
                if (data.brokers && data.brokers.length > 0) {
                    data.brokers.forEach(function (broker) {
                        brokers.push({
                            name:
                                (broker.company !== null
                                    ? broker.company
                                    : '') +
                                (broker.company !== null && broker.name !== null
                                    ? '-'
                                    : '') +
                                (broker.name !== null ? broker.name : ''),
                            id: broker.id
                        });
                    });
                }
                brokers.push({ name: '' });

                data.brokers = brokers;
            } else if (key === 'types') {
                data.types = data.types.map((type) => type.id);
            }

            let cur = currencyFields.indexOf(key);
            if (cur !== -1) {
                data[key + '_displayed'] = functions.thousands(
                    data[key + '_displayed']
                );
            }

            let unit = unitFields.indexOf(key);

            if (unit !== -1) {
                if (data[key + '_unit'] === 'feet') {
                    data[key] = functions.unit(
                        data[key],
                        data[key + '_unit'],
                        'edit'
                    );
                } else {
                    data[key] = functions.unit(data[key], data[key + '_unit']);
                }
            }

            let sqUnit = squaredUnitFields.indexOf(key);

            if (sqUnit !== -1) {
                if (data[key + '_unit'] === 'square_feet') {
                    data[key] = functions.squareUnit(
                        data[key],
                        data[key + '_unit'],
                        'edit'
                    );
                } else {
                    data[key] = functions.squareUnit(
                        data[key],
                        data[key + '_unit']
                    );
                }
            }
        });
        initialValues = data;
    } else {
        initialValues.asking_price_currency = 'usd';
        initialValues.estimated_price_currency = 'usd';
        initialValues.loa_unit = 'meters';
        initialValues.beam_unit = 'meters';
        initialValues.draft_min_unit = 'meters';
        initialValues.draft_max_unit = 'meters';
        initialValues.master_cabin_unit = 'square_meters';
        initialValues.sundeck_unit = 'square_meters';
        initialValues.scraped = false;
        initialValues.checked = false;
        initialValues.is_archived = false;
        initialValues.curation_level = -1;
    }

    return {
        countries: state.common.countries.map((st) => {
            return { value: st.name, label: st.name };
        }),
        states: state.common.states.map((st) => {
            return { value: st.name, label: st.name };
        }),
        types: state.common.types.map((type) => ({
            value: type.id,
            label: type.text
        })),
        initialValues: initialValues || {
            brokers: [{}],
            favourite: false
        },
        load: fetchYacht,
        formValues: selector(state, "is_archived", "delete_on"),
        country: selector(state, 'country'),
        favourite: selector(state, 'favourite'),
        realRates: state.rates.rates.rates,
        user_role: state.auth.user_role,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(FormBasics));
